import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

import PageContainer from "../../common/PageContainer/PageContainer";

type Props = {
  testID?: string;
};

const LoadingPage: React.FC<Props> = ({ testID }) => {
  return (
    <PageContainer>
      <Flex justifyContent={"center"} data-testid={testID}>
        <Spinner size={"xl"} />
      </Flex>
    </PageContainer>
  );
};

export default LoadingPage;
