import React from "react";
import { Flex } from "@chakra-ui/react";

import PageContainer from "../../common/PageContainer/PageContainer";

type Props = {
  testID?: string;
};

const BlankPage: React.FC<Props> = ({ testID }) => {
  return (
    <PageContainer>
      <Flex justifyContent={"center"} data-testid={testID}></Flex>
    </PageContainer>
  );
};

export default BlankPage;
