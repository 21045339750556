import { withAuthUser, AuthAction } from "next-firebase-auth";
import { ComponentType } from "react";
import BlankPage from "../../pages/BlankPage/BlankPage";
import LoadingPage from "../../pages/LoadingPage/LoadingPage";

export const withAuth = (component: ComponentType<unknown>) => {
  return withAuthUser({
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
    whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
    LoaderComponent: LoadingPage,
  })(component);
};

export const withUnauth = (component: ComponentType<unknown>) => {
  return withAuthUser({
    whenAuthed: AuthAction.REDIRECT_TO_APP,
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
    LoaderComponent: BlankPage,
  })(component);
};
