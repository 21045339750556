import { Center, Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

type Props = FlexProps;

const MainWhiteBox: React.FC<Props> = ({ children, gap = 4 }) => {
  return (
    <Center>
      <Flex
        flexDirection={"column"}
        bg={"white"}
        borderRadius={"xl"}
        p={{ base: 4, md: 8 }}
        gap={gap}
        width={{ base: "100%", lg: 1000 }}
      >
        {children}
      </Flex>
    </Center>
  );
};

export default MainWhiteBox;
