import Link from "next/link";
import { Button, Heading, Stack, Text } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { FaTwitter } from "react-icons/fa";

import MainWhiteBox from "../../common/MainWhiteBox/MainWhiteBox";
import PageContainer from "../../common/PageContainer/PageContainer";
import { useAuth } from "../../../hooks/useAuth";

const Login = () => {
  const { signInWithGoogle, signInWithTwitter } = useAuth();
  return (
    <PageContainer>
      <MainWhiteBox>
        <Heading size={"sm"}> {`ログイン / 新規登録`} </Heading>
        <Stack alignItems={"center"} width={"100%"}>
          <Button
            leftIcon={<FcGoogle />}
            bgColor={"white"}
            borderWidth={1}
            borderStyle={"solid"}
            onClick={signInWithGoogle}
          >
            Googleでログイン
          </Button>
          <Button
            colorScheme={"twitter"}
            leftIcon={<FaTwitter />}
            onClick={signInWithTwitter}
          >
            Twitterでログイン
          </Button>
          <Text fontSize={"xs"}>
            <Link href="/usage-terms">
              <a style={{ color: "blue", cursor: "pointer" }}>利用規約</a>
            </Link>
            ・
            <Link href="/privacy-policy">
              <a style={{ color: "blue", cursor: "pointer" }}>
                プライバシーポリシー
              </a>
            </Link>
            に同意の上、ログインください
          </Text>
        </Stack>
      </MainWhiteBox>
    </PageContainer>
  );
};

export default Login;
